<template lang="">
  <div>
    <b-card title="Sebaran Supplier Rekanan">
      <basemap
        height="60vh"
        :zoom="parseFloat(7)"
        dataurl="supplier/map"
      />
    </b-card>
  </div>
</template>
<script>
import { BCard,BRow,BCol } from 'bootstrap-vue'
import basemap from '@/views/base/BaseMapV2.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    basemap
  },
}
</script>
<style lang="">

</style>
